import React from 'react';
import { Chrono } from 'react-chrono';
import './Roadmap.scss';

const Roadmap: React.FC = () => {
  // Timeline data for react-chrono
  const timelineItems = [
    {
      title: "Beta Launch",
      cardTitle: "Coming Soon",
      cardSubtitle: "Beta Launch",
      cardDetailedText: "We will still begin testing the platform with a small group of users to gather feedback, identify accuracy issues, and improve the learning algorithm."
    },
    {
      title: "Full Launch",
      cardTitle: "Coming Soon",
      cardSubtitle: "The full launch of MedMeta",
      cardDetailedText: "Once we've had a change to gather feedback and refine the app, we will launch the full version of MedMeta, with daily open access article ingestion, generated flashcard decks, specialization focusing, guiz generation, fully cited chat, and a global question of the day for users to answer."
    },
    {
      title: "Audio",
      cardTitle: "Coming Soon",
      cardSubtitle: "Audio Features",
      cardDetailedText: "The first major feature we will be adding is the ability to listen to podcast-style summaries of the articles we ingest as well as weekly rundowns of published papers. Our goal is to deliver the content from our sources in as many ways as possible to cater to different learning styles."
    },
    {
      title: "Web Mode",
      cardTitle: "Coming Soon",
      cardSubtitle: "Web App",
      cardDetailedText: "We will also be developing a web app that will allow users to access MedMeta from a desktop or laptop computer."
    },
    {
      title: "Licensed Journals",
      cardTitle: "Coming Eventually",
      cardSubtitle: "Access to Non-Open Access Journals",
      cardDetailedText: "As a single developer bootstrapping this project, my resources are limited when negotiating licenses with closed publishers, but it is absolutey a goal to also source articles from the largest journals."
    },
    {
      title: "New Features",
      cardTitle: "Coming In The Future",
      cardSubtitle: "The next frontier",
      cardDetailedText: "We have a long list of features we want to add, but all of them will depend on how users use the app and what the changes in the broader AI world are. If we make it to this step you can expect more and more exciting features."
    }
  ];

  return (
    <section className="roadmap-page">
      <div className="content-container">
        <div className="roadmap-content">
          <h1 style={{color: 'var(--text-primary)'}}>Our Vision for the Future</h1>
          <p>
            We are a very new company, and while our initial launch is coming soon, we are already working on the next steps.
          </p>
          
          <div className="chrono-timeline-container">
            <Chrono
              items={timelineItems}
              mode="VERTICAL_ALTERNATING"
              theme={{
                primary: 'var(--primary)',
                secondary: 'var(--surface)',
                cardBgColor: 'var(--surface)',
                cardForeColor: 'var(--text-primary)',
                titleColor: 'var(--primary)',
                titleColorActive: 'var(--primary)'
              }}
              fontSizes={{
                cardSubtitle: '0.85rem',
                cardText: '0.9rem',
                cardTitle: '1.2rem',
                title: '1rem',
              }}
              cardHeight={200}
              scrollable={{ scrollbar: true }}
              enableOutline
              disableToolbar
              disableClickOnCircle={true}
              disableNavOnKey={true}
              disableTimeNav={true}
            />
          </div>
          
          <div className="roadmap-note">
            <p>
              Note: This roadmap is subject to change as we continue to gather user feedback and adapt to
              the evolving landscape of medical research and technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap; 