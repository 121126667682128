/**
 * API utilities for interacting with the backend
 */

// Base API URL
const API_BASE_URL = 'https://api.medmeta.ai';

/**
 * Interface for API responses
 */
interface ApiResponse<T> {
  success: boolean;
  message: string;
  data?: T;
}

/**
 * Signup form data interface
 */
export interface SignupFormData {
  first_name: string;
  last_name: string;
  email: string;
  customer_type: string;
  interested_in_beta: boolean;
}

/**
 * Submit signup form data to the API
 * @param formData The form data to submit
 * @returns Promise with API response
 */
export const submitSignupForm = async (formData: SignupFormData): Promise<ApiResponse<any>> => {
  try {
    const response = await fetch(`${API_BASE_URL}/public/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    // Handle rate limiting errors
    if (response.status === 429) {
      return {
        success: false,
        message: 'Too many signup attempts. Please try again later.',
      };
    }

    // Handle server errors
    if (!response.ok) {
      return {
        success: false,
        message: 'An error occurred while processing your request. Please try again later.',
      };
    }

    // Parse and return successful response
    const data = await response.json();
    return {
      success: true,
      message: data.message || 'Signup successful!',
      data,
    };
  } catch (error) {
    console.error('Error submitting signup form:', error);
    return {
      success: false,
      message: 'Network error. Please check your connection and try again.',
    };
  }
}; 