import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../utils/analytics';

// A mapping of paths to readable page titles
const pathToTitle: Record<string, string> = {
  '/': 'Home Page',
  '/about': 'About Page',
  '/roadmap': 'Roadmap Page',
  '/how-it-works': 'How It Works Page'
  // Add more routes as needed
};

// Hook to track page views
export const usePageTracking = (): void => {
  const location = useLocation();
  
  useEffect(() => {
    const currentPath = location.pathname;
    const pageTitle = pathToTitle[currentPath] || 'Unknown Page';
    
    // Track the page view
    trackPageView(currentPath, pageTitle);
    
    // You can also set document title here if needed
    document.title = `MedMeta - ${pageTitle}`;
  }, [location]);
}; 