import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { trackNavigation, trackButtonClick } from '../../utils/analytics';
import './Header.scss';

const Header: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const isDarkMode = theme === 'dark';
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Prevent body scrolling when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    trackButtonClick('menu_toggle', 'header');
    setIsMenuOpen(!isMenuOpen);
  };

  // Track theme toggle
  const handleThemeToggle = () => {
    trackButtonClick('theme_toggle', 'header');
    toggleTheme();
  };

  // Close the menu when a link is clicked and track navigation
  const handleLinkClick = (destination: string, linkName: string) => {
    trackNavigation(linkName, destination);
    
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className="main-header">
      <div className="container header-container">
        <div className="logo">
          <Link to="/" onClick={() => handleLinkClick('/', 'logo')}>
            <h1>MedMeta</h1>
          </Link>
        </div>
        <div className="header-right">
          <button 
            className="menu-toggle"
            onClick={toggleMenu}
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            aria-expanded={isMenuOpen}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </button>
          
          <nav className={`main-nav ${isMenuOpen ? 'open' : ''}`}>
            <ul className="nav-links">
              <li className={location.pathname === '/' ? 'active' : ''}>
                <Link to="/" onClick={() => handleLinkClick('/', 'home')}>Home</Link>
              </li>
              <li className={location.pathname === '/how-it-works' ? 'active' : ''}>
                <Link to="/how-it-works" onClick={() => handleLinkClick('/how-it-works', 'how_it_works')}>How It Works</Link>
              </li>
              <li className={location.pathname === '/roadmap' ? 'active' : ''}>
                <Link to="/roadmap" onClick={() => handleLinkClick('/roadmap', 'roadmap')}>Roadmap</Link>
              </li>
              <li className={location.pathname === '/about' ? 'active' : ''}>
                <Link to="/about" onClick={() => handleLinkClick('/about', 'about')}>About</Link>
              </li>
              
              
              <li className="mobile-only theme-toggle-item">
                <button 
                  className="theme-toggle-button"
                  onClick={handleThemeToggle}
                  aria-label={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
                  title={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
                >
                  {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </button>
              </li>
            </ul>
          </nav>
          
          {/* Overlay for mobile menu */}
          <div 
            className={`menu-overlay ${isMenuOpen ? 'active' : ''}`} 
            onClick={() => setIsMenuOpen(false)}
          />
          
          <div className="theme-toggle-container desktop-only">
            <button 
              className="theme-toggle-button"
              onClick={handleThemeToggle}
              aria-label={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
              title={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
            >
              {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header; 