import React from "react";
import "./About.scss";

const About: React.FC = () => {
  return (
    <section className="about-page">
      <div className="about-hero">
        <div className="content-container">
          <h1 className="about-hero__title">About MedMeta</h1>
          <div className="about-hero__image">
            {/* Placeholder for hero image */}
            <img className="image-placeholder" src={"/images/headshot.jpg"} />
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="about-content">
          <h2>Our Mission</h2>
          <p>
            At MedMeta we synthesize cutting-edge medical research into
            accessible educational resources, empowering healthcare
            professionals to stay at the forefront of medicine in the AI age.
          </p>
          <p>
            We bridge the gap between the exponential growth of medical
            literature and clinical practice by transforming complex journal
            articles into clear, actionable insights for doctors and medical
            students. Our mission is to enhance medical decision-making through
            comprehensive knowledge integration, enabling healthcare
            professionals to develop the broad perspective needed to effectively
            collaborate with AI systems while maintaining human oversight.
          </p>
          <p>
            At MedMeta, we believe that the future of medicine lies in the
            cooperation between human expertise and artificial intelligence. By
            providing healthcare professionals with efficient access to industry
            consensus across a wide range of topics, we prepare them to lead
            confidently in an evolving healthcare landscape where the ability to
            see the big picture becomes increasingly valuable.
          </p>

          <h2>Our Story</h2>
          <p>
            Founded in 2025, MedMeta was born from the challenge of navigating
            an ever increasing amount of machine learning research. Eli (the
            founder) was trying to navigate the speed of change in his own
            industry, and built an early prototye of what became MedMeta. In an
            attempt to use the tool in a higher impact field than the gordian
            knot of making machine learning tools for machine learning
            devlopers, Eli pivoted to medical research, and MedMeta was born.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
