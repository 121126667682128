// Firebase configuration
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzRKvRdGHy0SFjyJtaY1t9Jhl-LTZFab4",
  authDomain: "medmeta.firebaseapp.com",
  projectId: "medmeta",
  storageBucket: "medmeta.firebasestorage.app",
  messagingSenderId: "385988516098",
  appId: "1:385988516098:web:d1c92097b8593f6a0c0a1b",
  measurementId: "G-XCNVVH1XW9" // Google Analytics measurement ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics }; 