import React from "react";
import "./HowItWorks.scss";

const HowItWorks: React.FC = () => {
  return (
    <section className="how-it-works-page">
      <div className="how-it-works-hero">
        <div className="content-container">
          <h1 className="how-it-works-hero__title">How It Works</h1>
        </div>
      </div>
      <div className="content-container">
        <div className="how-it-works-content">
          <section className="section">
            <h2>How We Help You Learn</h2>
            <p>
              At the heart of MedMeta is our custom-developed recommendation model that personalizes your learning experience. This model:
            </p>
            <ul>
              <li>Analyzes your interaction patterns to understand your knowledge gaps and learning preferences</li>
              <li>Prioritizes content that optimizes knowledge retention based on principles of spaced repetition</li>
              <li>Balances exposure to reinforcing materials with new challenging concepts</li>
              <li>Adapts to your engagement patterns to maintain optimal learning momentum</li>
            </ul>
            <p>
              Rather than overwhelming you with content, our system ensures that each article, flashcard, or quiz presented is precisely what you need at that moment to advance your understanding. This approach helps you develop a broad yet nuanced view of medical consensus across various topics—preparing you to effectively collaborate with and oversee AI systems in clinical settings.
            </p>
          </section>

          <section className="section">
            <h2>How We Create Our Resources</h2>
            <p>
              Our content creation engine uses sophisticated semantic search technology to understand relationships between medical concepts across different sources. This technology enables us to:
            </p>
            <ul>
              <li>Generate comprehensive flashcard decks that highlight critical connections between related medical concepts</li>
              <li>Create intelligent quizzes that test understanding at varying levels of complexity</li>
              <li>Identify and suggest related articles that provide additional context or alternative perspectives</li>
            </ul>
            <p>
              Each resource undergoes a quality assurance process that ensures accuracy, relevance, and pedagogical value. By combining semantic understanding with medical expertise, we transform isolated research papers into interconnected learning materials that build cohesive knowledge structures.
            </p>
          </section>

          <section className="section">
            <h2>Where Our Data Comes From</h2>
            <p>
              MedMeta begins with open access medical journal articles from reputable sources. Our system gathers research that represents current medical consensus and emerging insights across specialties.
            </p>
            <p>
              These selected sources undergo programmatic ingestion through our custom-built pipeline, transforming PDFs and web content into structured data. Advanced large language models then process each source, extracting key elements including methodologies, findings, conclusions, and key concepts. Citations are created to provide clear informational provenance to the original research. This process preserves the scholarly integrity of the original research and makes it easy for users to navigate to it.
            </p>
          </section>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 