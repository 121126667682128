import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import { ScrollSection } from './components/ScrollSection/ScrollSection';
import CallToAction from './components/CallToAction/CallToAction';
import Roadmap from './components/Roadmap';
import About from './components/About';
import HowItWorks from './components/HowItWorks';
import { useTheme } from './context/ThemeContext';
import { usePageTracking } from './hooks/usePageTracking';
import './App.scss';

interface ColorSwatch {
  name: string;
  variable: string;
}

const HomePage: React.FC = () => {
  return (
    <>
      <Hero />
      <ScrollSection />
      <CallToAction />
    </>
  );
};

const App: React.FC = () => {
  const { theme } = useTheme();
  
  const themeColors: ColorSwatch[] = [
    { name: 'primary', variable: 'var(--primary)' },
    { name: 'secondary', variable: 'var(--secondary)' },
    { name: 'accent', variable: 'var(--accent)' },
    { name: 'text-primary', variable: 'var(--text-primary)' },
    { name: 'text-secondary', variable: 'var(--text-secondary)' },
    { name: 'background', variable: 'var(--background)' },
    { name: 'surface', variable: 'var(--surface)' },
    { name: 'border', variable: 'var(--border)' },
    { name: 'success', variable: 'var(--success)' },
    { name: 'error', variable: 'var(--error)' },
    { name: 'warning', variable: 'var(--warning)' },
    { name: 'link', variable: 'var(--link)' },
    { name: 'button-text', variable: 'var(--button-text)' },
  ];
  
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

// Separate component for content to use the router hooks
const AppContent: React.FC = () => {
  // Use the page tracking hook
  usePageTracking();
  
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
      </Routes>
    </div>
  );
};

export default App; 