import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';

// Page view tracking
export const trackPageView = (pagePath: string, pageTitle: string) => {
  if (analytics) {
    logEvent(analytics, 'page_view', {
      page_path: pagePath,
      page_title: pageTitle,
      page_location: window.location.href
    });
  }
};

// Signup flow events
export const trackSignupEvents = {
  // Track when the signup dialog is opened
  dialogOpened: (source: string) => {
    if (analytics) {
      logEvent(analytics, 'signup_dialog_opened', {
        source: source // e.g., 'hero', 'cta', etc.
      });
    }
  },
  
  // Track when the signup form is submitted
  formSubmitted: (customerType: string, interestedInBeta: boolean) => {
    if (analytics) {
      logEvent(analytics, 'signup_form_submitted', {
        customer_type: customerType,
        interested_in_beta: interestedInBeta
      });
    }
  },
  
  // Track when the signup is successful
  signupSuccess: (customerType: string) => {
    if (analytics) {
      logEvent(analytics, 'signup_completed', {
        customer_type: customerType
      });
      
      // Also track as a conversion
      logEvent(analytics, 'conversion', {
        conversion_type: 'signup',
        customer_type: customerType
      });
    }
  },
  
  // Track when there's an error during signup
  signupError: (errorMessage: string) => {
    if (analytics) {
      logEvent(analytics, 'signup_error', {
        error_message: errorMessage
      });
    }
  }
};

// Button click tracking
export const trackButtonClick = (buttonName: string, buttonLocation: string) => {
  if (analytics) {
    logEvent(analytics, 'button_click', {
      button_name: buttonName,
      button_location: buttonLocation
    });
  }
};

// Navigation tracking
export const trackNavigation = (linkName: string, linkDestination: string) => {
  if (analytics) {
    logEvent(analytics, 'navigation_click', {
      link_name: linkName,
      link_destination: linkDestination
    });
  }
}; 