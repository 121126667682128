import React, { useState } from "react";
import "./Hero.scss";
import mockupImage from "../../assets/heroPlaceholder.png";
import { useTheme } from "../../context/ThemeContext";
import { SignupDialog } from "../SignupDialog";
import { submitSignupForm, SignupFormData as ApiFormData } from "../../utils/api";
import { trackSignupEvents, trackButtonClick } from "../../utils/analytics";

// Define the form data interface (component internal version)
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  customerType: string;
  interestedInBeta: boolean;
}

const Hero: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const handleOpenDialog = () => {
    // Track that the dialog was opened from the hero section
    trackSignupEvents.dialogOpened('hero');
    setIsDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  
  const handleSubmitForm = async (formData: FormData) => {
    try {
      // Track form submission attempt
      trackSignupEvents.formSubmitted(formData.customerType, formData.interestedInBeta);
      
      // Convert from our component's FormData format to the API's expected format
      const apiFormData: ApiFormData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        customer_type: formData.customerType,
        interested_in_beta: formData.interestedInBeta
      };
      
      // Submit to the API
      const response = await submitSignupForm(apiFormData);
      
      if (response.success) {
        console.log('Form submitted successfully:', response);
        
        // Track successful signup (conversion)
        trackSignupEvents.signupSuccess(formData.customerType);
        
        return { success: true };
      } else {
        console.error('Error submitting form:', response.message);
        
        // Track signup error
        trackSignupEvents.signupError(response.message);
        
        return { 
          success: false, 
          error: response.message
        };
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      console.error('Error submitting form:', error);
      
      // Track signup error
      trackSignupEvents.signupError(errorMessage);
      
      return { 
        success: false, 
        error: 'An unexpected error occurred. Please try again.'
      };
    }
  };
  
  const handleSignupClick = () => {
    handleOpenDialog();
  };
  
  return (
    <section className="hero">
      <div className="content-container">
        <div className="hero__inner">
          <div className="hero__content">
            <h1 className="hero__title">Keep up with the latest research</h1>
            <p className="hero__subtitle">
              Medmeta collates the latest medical research into one place, with
              different ways to engage and learn from the conclusions. Our hope
              is to expose the information to physicians and medical students in
              multiple formats for different learning styles and in a way that
              is easy to understand and use.
            </p>
            <div className="hero__cta">
              <button className="btn btn-primary" onClick={handleSignupClick}>Sign up for notifications</button>
            </div>
          </div>
          <div className="hero__image">
            <div className="iphone-frame">
              <img
                src={isDarkMode ? "/images/homeDark.png" : "/images/homeLight.png"}
                alt="MedMeta mobile app interface"
                className="hero__mockup app-image"
              />
            </div>
          </div>
        </div>
      </div>
      
      <SignupDialog 
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitForm}
      />
    </section>
  );
};

export default Hero;
