import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './SignupDialog.scss';
import { useTheme } from '../../context/ThemeContext';

// Define the props for the dialog component
interface SignupDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => Promise<{ success: boolean; error?: string }>;
}

// Define the form data interface
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  customerType: string;
  interestedInBeta: boolean;
}

// Customer type options
const customerTypes = [
  'Resident Physician',
  'Physician Assistant',
  'Nurse Practitioner',
  'Med Student',
  'PA Student',
  'NP Student',
  'Medical Researcher',
  'Public Health Professional',
  'Attending Physician'
];

export const SignupDialog: React.FC<SignupDialogProps> = ({ isOpen, onClose, onSubmit }) => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    customerType: '',
    interestedInBeta: false
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Handle form input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear previous error when user starts typing again
    if (submitError) {
      setSubmitError(null);
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  // Reset form state when dialog closes
  const handleDialogClose = () => {
    // Wait for the exit animation to complete before resetting
    setTimeout(() => {
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        customerType: '',
        interestedInBeta: false
      });
      setSubmitError(null);
      setSubmitSuccess(false);
    }, 300);
    
    onClose();
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Set loading state
    setIsSubmitting(true);
    setSubmitError(null);
    
    try {
      // Call the onSubmit function provided as prop
      const result = await onSubmit(formData);
      
      if (result.success) {
        setSubmitSuccess(true);
      } else {
        setSubmitError(result.error || 'An error occurred while submitting the form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Animation variants
  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const dialogVariants = {
    hidden: { opacity: 0, y: -50, scale: 0.95 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1,
      transition: { type: 'spring', damping: 25, stiffness: 500 }
    },
    exit: { 
      opacity: 0,
      y: 50,
      scale: 0.95,
      transition: { duration: 0.2 }
    }
  };

  // Render success message if submission was successful
  if (submitSuccess) {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="dialog-backdrop"
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={handleDialogClose}
          >
            <motion.div 
              className={`signup-dialog ${theme} success-dialog`}
              variants={dialogVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={e => e.stopPropagation()}
            >
              <button className="close-button" onClick={handleDialogClose}>×</button>
              <div className="success-container">
                <h2>Thank You!</h2>
                <p>We've received your information and will notify you when MedMeta launches.</p>
                <button className="btn btn-primary" onClick={handleDialogClose}>Close</button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="dialog-backdrop"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={handleDialogClose}
        >
          <motion.div 
            className={`signup-dialog ${theme}`}
            variants={dialogVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={e => e.stopPropagation()}
          >
            <button className="close-button" onClick={handleDialogClose}>×</button>
            <h2>Sign up for notifications</h2>
            <p>Be the first to know when MedMeta launches!</p>
            
            {submitError && (
              <div className="error-message">
                {submitError}
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    disabled={isSubmitting}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="customerType">What best describes you?</label>
                <select
                  id="customerType"
                  name="customerType"
                  value={formData.customerType}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting}
                >
                  <option value="">Select an option</option>
                  {customerTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              
              <div className="form-group checkbox-group">
                <input
                  type="checkbox"
                  id="interestedInBeta"
                  name="interestedInBeta"
                  checked={formData.interestedInBeta}
                  onChange={handleCheckboxChange}
                  disabled={isSubmitting}
                />
                <label htmlFor="interestedInBeta">
                  I'm interested in beta testing the app
                </label>
              </div>
              
              <button 
                type="submit" 
                className={`btn btn-primary submit-button ${isSubmitting ? 'loading' : ''}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SignupDialog; 