import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./ScrollSection.scss";
import { useTheme } from "../../context/ThemeContext";

interface ScrollItemProps {
  title: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  isImageLeft?: boolean;
}

const ScrollItem: React.FC<ScrollItemProps> = ({
  title,
  description,
  imageSrc,
  imageAlt,
  isImageLeft,
}) => {


  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  const textVariants = {
    hidden: { x: isImageLeft ? 100 : -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const imageVariants = {
    hidden: { x: isImageLeft ? -100 : 100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      ref={ref}
      className={`scroll-item ${isImageLeft ? "image-left" : "image-right"}`}
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      <motion.div className="scroll-item__content" variants={textVariants}>
        <h2>{title}</h2>
        <p>{description}</p>
      </motion.div>
      <motion.div className="scroll-item__image" variants={imageVariants}>
        <div className="iphone-frame">
          <img src={imageSrc} alt={imageAlt} className="app-image" />
        </div>
      </motion.div>
    </motion.div>
  );
};

export const ScrollSection: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const isDarkMode = theme === "dark";

  const items = [
    {
      title: "Fully Cited Sources",
      description:
        "We use full citations of the sources our models use to generate their content, be it quis questions, flashcards, or chat responses. Tap the quote icon anywhere in the app to see the citation and visit the source.",
      imageSrc: isDarkMode ? "/images/citationD.png" : "/images/citationL.png",
      imageAlt: "Chat page with a citation at the bottom",
      isImageLeft: false,
    },
    {
      title: "Algorithmicly Driven Education",
      description:
        "The content shown in the app is driven by a recommendation algorithm that uses a mixture of spaced repition and machine learning to optimize which topics and concepts are included in your learning exercises to maximize the learning effect from each sessions.",
      imageSrc: isDarkMode ? "/images/quizDark.png" : "/images/quizLight.png",
      imageAlt: "System Integration Illustration",
      isImageLeft: true,
    },
    {
      title: "Learn Your Way",
      description:
        "Medmeta can generate flashcards, quizzes, conversation chats, and flag releated articles and allow you to save sources and topics for later review.",
      imageSrc: isDarkMode ? "/images/flashcardsDark.png" : "/images/flashcardsLight.png",
      imageAlt: "Patient Care Illustration",
      isImageLeft: false,
    },
  ];

  return (
    <section className="scroll-section">
      {items.map((item, index) => (
        <ScrollItem key={index} {...item} />
      ))}
    </section>
  );
};
