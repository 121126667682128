import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './CallToAction.scss';
import { SignupDialog } from '../SignupDialog';
import { useTheme } from '../../context/ThemeContext';
import { submitSignupForm, SignupFormData as ApiFormData } from "../../utils/api";
import { trackSignupEvents, trackButtonClick } from "../../utils/analytics";

// Define the form data interface (component internal version)
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  customerType: string;
  interestedInBeta: boolean;
}

export const CallToAction: React.FC = () => {
  const { theme } = useTheme();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const handleOpenDialog = () => {
    trackSignupEvents.dialogOpened('cta');
    setIsDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  
  const handleSubmitForm = async (formData: FormData) => {
    try {
      trackSignupEvents.formSubmitted(formData.customerType, formData.interestedInBeta);
      // Convert from our component's FormData format to the API's expected format
      const apiFormData: ApiFormData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        customer_type: formData.customerType,
        interested_in_beta: formData.interestedInBeta
      };
      
      // Submit to the API
      const response = await submitSignupForm(apiFormData);
      
      if (response.success) {
        console.log('Form submitted successfully:', response);
        trackSignupEvents.signupSuccess(formData.customerType);
        // No need to close the dialog here as the success state will be shown
        return { success: true };
      } else {
        console.error('Error submitting form:', response.message);
        trackSignupEvents.signupError(response.message);
        return { 
          success: false, 
          error: response.message
        };
      }
    } catch (error) {
      console.error('Error submitting form:', error);

      // Track signup error
      trackSignupEvents.signupError(error instanceof Error ? error.message : 'An unexpected error occurred');
      return { 
        success: false, 
        error: 'An unexpected error occurred. Please try again.'
      };
    }
  };

  const ctaVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { 
        duration: 0.8, 
        ease: "easeOut" 
      } 
    },
  };

  return (
    <section className="cta-container">
      <motion.div
        ref={ref}
        className="cta-section"
        variants={ctaVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <h2 className="cta-section__headline">Join the Medical Knowledge Revolution</h2>
        <p className="cta-section__description">
          Our mission is to keep medical knowledge at the cutting edge while ensuring researchers 
          receive the proper credit they deserve. Together, we can create a more informed, 
          collaborative, and innovative healthcare community.
        </p>
        <div className="cta-section__button-container">
          <button className="btn btn-primary" onClick={handleOpenDialog}>Sign up for notifications</button>
        </div>
      </motion.div>
      
      <SignupDialog 
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitForm}
      />
    </section>
  );
};

export default CallToAction; 